
@font-face {
  font-family: 'WitchFont';
  src: local('WitchFont'), url(./fonts/ArtOfIlluminating.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',                            'embedded-opentype', and 'svg' */
}

.App {
  background-color: #171717;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('./assets/background.png');
  background-size: cover;
}

html, body, #root, .App {
  height: 100%;
  min-height: 100%;
}

a {
  text-decoration: none;
  transition: color 200ms linear;
}

.title {
  font-family: WitchFont, sans-serif;
  background: linear-gradient(to right, #ef5350, #f48fb1, #7e57c2, #2196f3, #26c6da, #43a047, #eeff41, #f9a825, #ff5722);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.witch-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10vh;
  color: white;
}

.witch-logo {
  width: 20%;
}
.status-message {
  color: #ffffff;
}

.socials {
  width: fit-content;
}
.socials{
  color: white
}

.socials a {
  color: #349164;
}

.socials a:hover {
  color: #ffffff
}

